<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <h1>
          Zonas
          <v-btn @click="generateCoverageZoneObject()" class="ml-8">
            Actualizar cobertura
          </v-btn>
        </h1>
      </v-col>
    </v-row>

    <v-row class="px-2">
      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCountry"
          :items="countries"
          placeholder="Escoge el pais"
          outlined
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCity"
          :items="cities"
          placeholder="Escoge la ciudad"
          outlined
          :disabled="!selectedCountry"
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col>
      <v-col cols="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            :disabled="!selectedCity"
            @click="newZone()"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nueva zona
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="zones"
          :search="$store.state.search"
          :items-per-page="10"
          :loading="loading"
          :sort-desc="true"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position:relative;">
              <div class="inner-lottie">
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>
          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="editZone(item)" small color="primary">
              Editar
            </v-btn>
            <v-btn
              @click="deleteZone(item)"
              small
              class="ml-3 white--text"
              color="red darken-4"
              >Eliminar</v-btn
            >
          </template>
          <template v-slot:[`item.country`]="{ item }">
            {{ item.countryName }}
          </template>
          <template v-slot:[`item.city`]="{ item }">
            {{ item.cityName }}
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="modalAddEdit" max-width="900px">
      <add-edit-zone
        :type="type"
        :city="selectedCity"
        :country="selectedCountry"
        @cancel="modalAddEdit = false"
        @success="handleSuccess"
        :key="randomKey"
        :item="itemToEdit"
      ></add-edit-zone>
    </v-dialog>

    <!-- modal delete city -->
    <v-dialog max-width="400" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right:10px; top:10px;"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ itemToDelete.name }}</span
          >?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius:15px"
            class="mb-3"
            dark
            @click="confirmDelete()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import addEditZone from "./add-edit-zone";

export default {
  name: "zones",
  components: {
    lottie: Lottie,
    addEditZone,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      saving: false,
      snackbarText: "",
      snackbar: false,
      zones: [],
      countries: [],
      selectedCountry: "",
      cities: [],
      selectedCity: "",
      modalAddEdit: false,
      itemToDelete: null,
      modalDelete: false,
      type: "",
      itemToEdit: null,
      randomKey: 0,
      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Ciudad",
          value: "city",
        },
        {
          text: "Pais",
          value: "country",
        },
        {
          text: "Código HEX",
          value: "color",
        },
        {
          text: "Activo",
          value: "active",
        },
        {
          value: "options",
          align: "end",
          width: "200px",
        },
      ],
    };
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "zonas");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    await this.getCountries();
  },
  methods: {
    generateCoverageZoneObject() {
      if (confirm("¿Estás seguro de actualizar cobertura de zonas?")) {
        let tempText = "";
        let str1 = `{"type": "FeatureCollection", "features": [`;
        let str2 = "]}";
        db.collection("zones")
          .where("deleted", "==", false)
          .where("active", "==", true)
          .get()
          .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              tempText += `
            {"type": "Feature",
              "properties": {
                "name": "${doc.data().name}",
                "color": "${doc.data().color}",
                "country": "${doc.data().countryId}",
                "city": "${doc.data().cityId}"
              },
             ${doc.data().geoJson}
            },`;
            });
            const mainText = tempText.slice(0, -1);
            this.coverageZone = str1 + mainText + str2;
            this.updatingSettingCoverZone(this.coverageZone);
          })
          .catch((err) => {
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          });
      }
    },
    updatingSettingCoverZone(coverageZone) {
      this.saving = true;
      db.collection("settingsTest")
        .doc("global")
        .update({
          coverageZone: coverageZone,
        })
        .then(() => {
          this.saving = false;
          this.snackbarText = "Información actualizada correctamente.";
          this.snackbar = true;
        })
        .catch((err) => {
          this.saving = false;
          this.snackbarText = `Ocurrió un error inesperado, inténtelo nuevamente, ${err}`;
          this.snackbar = true;
        });
    },
    getCountries() {
      let countries = this.$binding(
        "countries",
        db.collection("countries").where("deleted", "==", false)
      )
        .then(() => (this.loading = false))
        .catch((error) => {
          this.snackbarText = `Ocurio un error: ${error}`;
          this.snackbar = true;
        });
    },
    newZone() {
      this.randomKey = Math.random();
      this.type = "new";
      this.itemToEdit = null;
      this.modalAddEdit = true;
    },
    editZone(item) {
      this.randomKey = Math.random();
      this.type = "edit";
      this.itemToEdit = item;
      this.modalAddEdit = true;
    },
    deleteZone(item) {
      this.itemToDelete = item;
      this.modalDelete = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        db.collection("zones")
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Zona eliminada";
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
    handleSuccess(data) {
      this.snackbarText = data;
      this.snackbar = true;
      this.modalAddEdit = false;
    },
    switchControlChanged(data, zoneId, zone) {
      if (data && zoneId) {
        this.saving = true;

        db.collection("zones")
          .doc(zoneId)
          .update({
            active: data.active,
          })
          .then(() => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            zone.active = !zone.active;
          });
      }
    },
  },
  watch: {
    selectedCountry(countryId) {
      this.zones = [];
      this.selectedCity = "";
      this.$binding(
        "cities",
        db
          .collection("cities")
          .where("deleted", "==", false)
          .where("countryId", "==", countryId)
      )
        .then()
        .catch((error) => {
          this.snackbarText = `Ocurio un error: ${error}`;
          this.snackbar = true;
        });
    },
    selectedCity(cityId) {
      this.loading = true;
      this.$binding(
        "zones",
        db
          .collection("zones")
          .where("deleted", "==", false)
          .where("cityId", "==", cityId)
      )
        .then(() => (this.loading = false))
        .catch((error) => {
          this.snackbarText = `Ocurio un error: ${error}`;
          this.snackbar = true;
        });
    },
    zones() {
      this.zones.forEach((zone) => {
        if (zone.countryId) {
          let country = this.countries.find(
            (item) => item[".key"] == zone.countryId
          );
          zone.countryName = country.name ? country.name : "";
        }

        if (zone.cityId) {
          let city = this.cities.find((item) => item[".key"] == zone.cityId);
          zone.cityName = city.name ? city.name : "";
        }
      });
    },
  },
};
</script>

<style>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
