<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <h3>Días de la semana</h3>
        <v-divider></v-divider>
        <div class="days">
          <v-list>
            <v-list-item-group v-model="item" color="primary">
              <v-list-item
                v-for="(day, i) in days"
                :key="i"
                @click="showSchedule(day.value)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ day.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>

      <v-col cols="12" md="6">
        <transition
          enter-active-class="animated fadeIn"
          leave-active-class="animated"
          mode="out-in"
        >
          <v-card
            v-if="daySelected && waiting"
            class="text-left pa-1 ma-1 flex-grow-1 mb-0"
            :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'white'"
            elevation="0"
            style="border-radius: 17px !important; overflow: hidden"
          >
            <v-card-text class="text--primary">
              <h3>Horarios del día seleccionado</h3>
              <v-divider></v-divider>
              <v-row class="mt-3 ml-3">
                <v-col cols="5"> Hora inicial </v-col>
                <v-col cols="5"> Hora final </v-col>
              </v-row>

              <div
                v-for="(item, index) of schedule[daySelected]"
                :key="index"
                class="schedule"
              >
                <v-row>
                  <v-col cols="2">
                    <v-chip color="gray">
                      {{ index + 1 }}
                    </v-chip>
                  </v-col>

                  <v-col cols="5">
                    <a-time-picker
                      :open.sync="item.activeOpens"
                      @change="changeOpens($event, index)"
                      format="HH:mm:ss"
                      :default-value="
                        typeof item.availableFrom === 'string'
                          ? moment(`${item.availableFrom}`, 'h:mm:ss')
                          : item.availableFrom
                      "
                    >
                      <a-button
                        style="border-radius: 50px"
                        slot="addon"
                        block
                        size="small"
                        type="primary"
                        @click="handleClose(1, index)"
                      >
                        Aceptar
                      </a-button>
                    </a-time-picker>
                  </v-col>

                  <v-col cols="5">
                    <a-time-picker
                      :open.sync="item.activeCloses"
                      @change="changeCloses($event, index)"
                      format="HH:mm:ss"
                      :default-value="
                        typeof item.availableTo === 'string'
                          ? moment(`${item.availableTo}`, 'h:mm:ss')
                          : item.availableTo
                      "
                    >
                      <a-button
                        style="border-radius: 50px"
                        slot="addon"
                        block
                        size="small"
                        type="primary"
                        @click="handleClose(2, index)"
                      >
                        Aceptar
                      </a-button>
                    </a-time-picker>
                  </v-col>

                  <div class="delete-schedule-container">
                    <p
                      @click="removeSchedule(index)"
                      class="delete-schedule mt-1 mb-2"
                    >
                      Eliminar
                    </p>
                  </div>
                </v-row>
              </div>

              <div class="add-schedule">
                <v-btn
                  @click="addSchedule"
                  class="mx-2 mt-3"
                  fab
                  dark
                  color="primary"
                >
                  <v-icon>fas fa-plus</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Button } from "ant-design-vue";
import { TimePicker } from "ant-design-vue";
import moment from "moment-timezone";
import "moment/locale/fr";

export default {
  props: ["schedule"],
  components: {
    "a-time-picker": TimePicker,
    "a-button": Button,
  },
  data() {
    return {
      item: 0,
      open: false,
      days: [
        { text: "Lunes", value: "monday" },
        { text: "Martes", value: "tuesday" },
        { text: "Miércoles", value: "wednesday" },
        { text: "Jueves", value: "thursday" },
        { text: "Viernes", value: "friday" },
        { text: "Sábado", value: "saturday" },
        { text: "Domingo", value: "sunday" },
      ],
      time: null,
      daySelected: null,
      waiting: true,
      position: 0,
    };
  },
  methods: {
    moment,
    changeOpens(time, index) {
      this.schedule[this.daySelected][index].availableFrom =
        moment(time).format("HH:mm:ss");
    },
    changeCloses(time, index) {
      this.schedule[this.daySelected][index].availableTo =
        moment(time).format("HH:mm:ss");
    },
    showSchedule(day) {
      this.waiting = false;
      this.daySelected = day;
      setTimeout(() => {
        this.waiting = true;
      }, 100);
    },
    removeSchedule(position) {
      this.waiting = false;
      this.schedule[this.daySelected].splice(position, 1);
      setTimeout(() => {
        this.waiting = true;
      }, 100);
    },
    handleClose(order, index) {
      if (order == 1) {
        this.schedule[this.daySelected][index].activeOpens = false;
      } else {
        this.schedule[this.daySelected][index].activeCloses = false;
      }
    },
    addSchedule() {
      this.waiting = false;
      this.schedule[this.daySelected].push({
        availableFrom: "00:00:00",
        availableTo: "00:00:00",
      });
      setTimeout(() => {
        this.waiting = true;
      }, 100);
    },
  },
  mounted() {
    this.daySelected = "monday";
  },
};
</script>

<style scoped>
.schedule {
  width: 100%;
  margin-top: 10px;
}
.days {
  background-color: white !important;
  width: 100%;
  min-height: 300px;
  margin-top: 10px;
}
.theme--light.v-list {
  background: white !important;
}
.add-schedule {
  display: flex;
  justify-content: flex-end;
}
.delete-schedule-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 10%;
}
.delete-schedule {
  margin: 0;
  cursor: pointer;
  padding-right: 5px;
  color: #f06f3d;
}
</style>
