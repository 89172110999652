<template>
  <div>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">
          {{ type == "new" ? "Zona nueva" : "Editar zona" }}
        </span>

        <v-btn
          style="right:10px; top:10px;"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-snackbar top :timeout="3000" v-model="snackbar">
            {{ snackbarText }}
          </v-snackbar>
          <v-overlay class="loading-center" :value="saving">
            <Lottie
              :options="defaultOptions"
              :height="300"
              :width="400"
              v-on:animCreated="handleAnimation"
            />
          </v-overlay>
          <v-row>
            <v-col cols="12">
              <p>Nombre de la zona</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el nombre"
                v-model.trim="zone.name"
              />
            </v-col>

            <v-col cols="12">
              <p>Horario</p>
              <schedule :schedule="zone.schedule" />
            </v-col>

            <v-col cols="12" md="6">
              <p>Color</p>
              <v-color-picker
                dot-size="18"
                hide-canvas
                hide-mode-switch
                mode="hexa"
                v-model="zone.color"
              ></v-color-picker>
            </v-col>

            <v-col cols="12" md="6">
              <p>geoJson</p>
              <v-textarea
                solo
                name="input-7-4"
                label="solamente el object 'geometry' por favor"
                v-model.trim="zone.geoJson"
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="6">
              <p>ZIP</p>
              <input
                class="control-input"
                type="text"
                placeholder="Código zip"
                v-model.trim="zone.zipCode"
              />
            </v-col>
          </v-row>

          <v-divider class="mt-4"></v-divider>

          <v-row class="pa-0 ma-0 mt-8">
            <v-col cols="8">
              <v-row justify="start">
                <v-switch label="Activar zona" v-model="zone.active"></v-switch>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row justify="end">
                <v-btn
                  v-if="type == 'new'"
                  class="save-btn mt-3"
                  color="primary"
                  @click="saveZone()"
                  >Crear</v-btn
                >
                <v-btn
                  v-else
                  @click="updateCoupon()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import schedule from "./schedule";

export default {
  name: "add-edit-zoone",
  props: {
    item: Object,
    city: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    Lottie,
    schedule,
  },
  mixins: [lottieSettings],
  data() {
    return {
      saving: false,
      snackbarText: "",
      snackbar: false,
      zone: {
        active: true,
        color: "",
        name: "",
        zipCode: "11101",
        geoJson: "",
        schedule: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
      },
    };
  },
  mounted() {
    let scrollPosition = document.getElementsByClassName("v-dialog")[0];
    if (scrollPosition) scrollPosition.scrollTop = 0;

    if (this.type == "edit") {
      this.zone = Object.assign({}, this.item);
    }
  },
  methods: {
    saveZone() {
      if (!this.zone.name || !this.zone.geoJson || !this.validateGeoJson()) {
        this.snackbarText = "Debe llenar todos los campos correctamente";
        this.snackbar = true;
        return;
      } else {
        this.saving = true;
        this.zone.createdAt = new Date();
        this.zone.modifiedAt = new Date();
        this.zone.createdBy = this.$store.state.user[".key"];
        this.zone.deleted = false;
        this.zone.cityId = this.city;
        this.zone.countryId = this.country;

        db.collection("zones")
          .add(this.zone)
          .then(() => {
            this.saving = false;
            this.$emit("success", "Zona creada exitosamente.");
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText = `Ocurrió un error inesperado, inténtelo nuevamente, ${err}`;
            this.snackbar = true;
          });
      }
    },
    updateCoupon() {
      if (!this.zone.name || !this.zone.geoJson || !this.validateGeoJson()) {
        this.snackbarText = "Debe llenar todos los campos correctamente";
        this.snackbar = true;
        return;
      } else {
        this.saving = true;
        const docId = this.zone[".key"];
        const data = {
          modifiedAt: new Date(),
          modifiedBy: this.$store.state.user[".key"],
          active: this.zone.active,
          color: this.zone.color,
          name: this.zone.name,
          zipCode: this.zone.zipCode,
          geoJson: this.zone.geoJson,
          schedule: this.zone.schedule,
        };

        db.collection("zones")
          .doc(docId)
          .update(data)
          .then(() => {
            this.saving = false;
            this.$emit("success", "Zona actualizada exitosamente.");
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText = `Ocurrió un error inesperado, inténtelo nuevamente, ${err}`;
            this.snackbar = true;
          });
      }
    },
    validateGeoJson() {
      if (
        this.zone.geoJson.slice(0, 13) == '"geometry": {' &&
        this.zone.geoJson.slice(this.zone.geoJson.length - 1) == "}"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
